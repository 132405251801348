<template>
  <div style="padding: 20px">
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <div class="box">
      <p class="h2 head-text">Bulk Reassign Account Officer</p>
    </div>

    <hr style="border: 1.3px solid #e5e5e5" />

    <div class="box" v-if="userCanViewAccountOfficer">
      <div class="dash-box">
        <button
          :disabled="!allItemToBatchAssign.length"
          :class="
            !allItemToBatchAssign.length ? 'disable-btn mr-5' : 'btn-black mr-5'
          "
          style="width:180px"
          @click="processReassign"
        >
          Bulk Reassign
        </button>
        <button 
          style="width:185px"
          @click="processUpdateAccountOfficer"
          class="btn-black mr-5"
        >
          Update Account Officer
        </button>
        <button
          type="button"
          class="
            button
            page__body__header__button
            button--grey
            text--capital
          "
          @click="toggleFilterModal"
          style="width:165px"
        >
          <span class="d-inline-flex mr-2">filter</span>
        </button>
      </div>

      <div class="page__head_subtitle mt-5">
        <div style="display:flex; width:50%; font-size:14px" v-if="filterText">
          <div style="width:80%">
            {{ filterText }}
          </div>
          <div style="display:flex;width:10%">
            <span style="cursor:pointer;font-size:17px" @click="editFilter">
              <i style="padding-right:5px" class="bi bi-pencil-square"></i>
            </span>
            <span style="cursor:pointer;font-size:17px" @click="deleteFilter">
              <i style="padding-left:5px" class="bi bi-x-circle-fill"></i>
            </span>
          </div>
        </div>
      </div>

      <!-- <div class="hid-box">
        <button
          type="button"
          class="
            w-40
            button
            page__body__header__button
            button--grey
            text--capital
          "
          style="width:185px"
          @click="toggleFilterModal"
        >
          <span class="d-inline-flex mr-2">filter</span>
        </button>

        <div class="align-self-center mr-5 mx-2 dropdown">
          <button
            class="
              button button--grey
              text--capital text--small
              d-flex
              align-items-center
            "
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            export
            <span class="dropdown__icon">
              <ion-icon name="caret-down-outline"></ion-icon>
            </span>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a
              href="javascript:void(0)"
              @click="pdfDownload"
              class="dropdown-item text--capital"
              >PDF</a
            >
            <a
              href="javascript:void(0)"
              @click="processDownload"
              class="dropdown-item text--capital"
              >excel</a
            >
          </div>
        </div>
      </div> -->
    </div>

    <hr style="border: 1.3px solid #e5e5e5" />

    <div class="ma-table">
      <div class="ma-thead">
        <div style="width: 5%" class="thead-child">
          <label
            for="a"
            class="has-checkbox page__body__header__label text--capital"
          >
            <input
              type="checkbox"
              id="a"
              class="form-"
              @click="selectAllRows"
              v-model="selectAll"
            />
            <span class="checkmark checkmark2"></span>
          </label>
        </div>
        <div
          style="width: 10.6%"
          v-for="(text, i) in thead_texts"
          :key="i"
          class="thead-child"
        >
          {{ text }}
        </div>
      </div>

      <hr style="border: 0.6px solid #e5e5e5" />

      <div v-if="!userCanViewAccountOfficer">
        <div
          class="
            d-flex
            flex-column
            align-items-center
            justify-content-center
            p5
          "
        >
          <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

          <span
            class="d-block text--danger text--600 text--medium mt-3 mb-5"
            >You do not have permission to view this dashboard</span
          >
        </div>
      </div>

      <div v-else-if="bulkRepayments.length > 0">
        <div v-for="text in bulkRepayments" :key="text.accountKey">
          <div class="ma-tbody">
            <div style="width: 5%" class="tbody-child">
              <label
                :for="text.accountKey"
                class="has-checkbox page__body__header__label text--capital"
              >
                <input
                  type="checkbox"
                  :id="text.accountKey"
                  @change="selectRows"
                  class="form-"
                  :value="text.accountKey"
                  v-model="allItemToBatchAssign"
                />
                <span class="checkmark checkmark2"></span>
              </label>
            </div>
            <div
              style="width: 10.6%; text-align: start; word-wrap: break-word"
              class="tbody-child text--capital"
            >
              {{ text.productName }}
            </div>
            <div style="width: 10.6%" class="tbody-child text--capital">
              {{ text.customerName }}
            </div>
            <div style="width: 10.6%" class="tbody-child text--capital">
              {{ text.accountType }}
            </div>
            <div style="width: 10.6%" class="tbody-child text--capital">
              {{ text.accountId }}
            </div>
            <div style="width: 10.6%" class="tbody-child text--capital">
              {{ text.balance | currency }}
            </div>
            <div style="width: 10.6%" class="tbody-child text--capital">
              {{ text.branchName }}
            </div>
            <div style="width: 10.6%" class="tbody-child text--capital">
              {{ text.creationDate | formatDatePipe }}
            </div>
            <div style="width: 10.6%" class="tbody-child text--capital">
              {{ text.accountOfficer }}
            </div>
            <div style="width: 10.6%" class="tbody-child dropdown">
              <button
                class="btn dropdown-toggle action"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Action
              </button>
              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                v-if="userCanViewAccountOfficer"
              >
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="processSingleReassign(text.accountKey, text.customerKey, text.accountOfficerKey)"
                >
                  Reassign Account Officer
                </a>
              </div>
            </div>
          </div>

          <hr style="border: 0.6px solid #e5e5e5" />
        </div>

        <div class="pagination">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="text--capital text--regular pagination__text">
              row per page:
              <span
                class="d-inline-block text--primary"
                style="padding-right: 15px"
              >
                <select @change="changeLength" v-model="fetchAccount.pageSize">
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="30">30</option>
                  <option :value="50">50</option>
                </select>
              </span>
              <span class="d-inline-block text--primary">{{
                (!paginations.hasPrevious || !paginations.hasPreviousPage)
                  ? paginations.currentPage
                  : fetchAccount.pageSize * (paginations.currentPage - 1) + 1
              }}</span>
              -
              <span class="d-inline-block text--primary">{{
                (paginations.hasNext || paginations.hasNextPage)
                  ? fetchAccount.pageSize * paginations.currentPage
                  : paginations.totalCount
              }}</span>
              of
              <span>{{ paginations ? paginations.totalCount : 0 }}</span>
            </div>
            <div
              class="
                pagination__buttons
                d-flex
                align-items-center
                justify-content-between
              "
              v-if="paginations"
            >
              <button
                class="button button--sm text--regular"
                @click="changePage('previous')"
                :disabled="!(paginations.hasPrevious || paginations.hasPreviousPage)"
              >
                <ion-icon name="chevron-back-outline"></ion-icon>Previous
              </button>
              <!-- <button
                v-for="page in paginations.totalPages"
                :key="page"
                :class="{
                  'text--white button--primary ':
                    paginations.currentPage === page,
                }"
                class="button text--regular button--sm ml-2"
                @click="changePage(page)"
              >
                {{ page }}
              </button> -->
              <button
                class="button text--regular button--sm ml-2"
                @click="changePage('next')"
                :disabled="!(paginations.hasNext || paginations.hasNextPage)"
              >
                Next<ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="loading && !bulkRepayments.length">
        <div class="d-flex align-items-center justify-content-center p5">
          <div
            class="spinner-border"
            style="width: 3rem; height: 3rem"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <span class="text--black text--600 text--medium d-inline-flex ml-4"
            >Please wait</span
          >
        </div>
      </div>

      <div v-else>
        <div
          class="
            d-flex
            flex-column
            align-items-center
            justify-content-center
            p5
          "
        >
          <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

          <span class="d-block text--danger text--600 text--medium mt-3 mb-5">
            No data available</span
          >
        </div>
      </div>
    </div>

    <CreateFilter
      :show="showFilterModal"
      @close="toggleFilterModal"
      @send-activate-filter="sendFilter"
      @reload="reloadFilter"
      :filterEditValue="filterEdit"
      :showEditedValue="showEditedValue"
      @alertError="alertError"
      :filterHeaderName="'Bulk Reassignment'"
      filterCategory="bulk_reassign_officer"
    />

    <div
      class="modal modal__confirm show"
      id="cropImageModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: dialog ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                {{ modalText.header }}
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="closeModal()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <!-- <span class="modal__header__subtitle text--capital">
              For Users
            </span> -->
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                {{ modalText.subHead }}
              </h5>

              <div v-if="modalText.fromBulk" class="col-12" style="padding:0px">
                <div class="form__item">
                  <label class="form__label">Account Officer</label>
                  <select
                    class="select--lg w-100"
                    v-model="assignedOfficerKey"
                  >
                    <option disabled value="null">
                      Select Account Officer
                    </option>
                    <option
                      v-for="item in accountOfficers"
                      v-bind:key="item.userKey"
                      v-bind:value="item.userKey"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div v-if="modalText.fromUpdate">
                <div class="col-12" style="padding:0px">
                  <div class="form__item">
                    <label class="form__label">Select all accounts from:</label>
                    <select
                      class="select--lg w-100"
                      v-model="assignedOfficerKey"
                    >
                      <option disabled value="null">
                        Select Account Officer
                      </option>
                      <option
                        v-for="item in accountOfficers"
                        v-bind:key="item.userKey"
                        v-bind:value="item.userKey"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-12" style="padding:0px">
                  <div class="form__item">
                    <label class="form__label">Update all accounts to:</label>
                    <select
                      class="select--lg w-100"
                      v-model="assignedOfficerKey2"
                    >
                      <option disabled value="null">
                        Select Account Officer
                      </option>
                      <option
                        v-for="item in accountOfficers"
                        v-bind:key="item.userKey"
                        v-bind:value="item.userKey"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="closeModal()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="bulkAssign()"
                >
                  {{ isSaving ? "Saving..." : modalText.buttonText }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
// import JsonExcel from "vue-json-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import * as XLSX from "xlsx";

import ApiService from "@/core/services/api.service";
import AccessApiService from "@/core/services/access.service";
import { GET_ALL_BULK_REASSIGNMENT } from "@/core/services/store/bulkreassign.module.js";

export default {
  name: "BulkReassignment",
  components: {
    // downloadExcel: JsonExcel,
    CreateFilter: () =>
      import(
        /* WebpackChunkName: "CreateFilter" */ "@/view/pages/deposit/filter.vue"
      ),
  },
  data() {
    return {
      json_fields: {
        "Product Name": 'product_name',
        "Customer Name": 'customer_name',
        "Account Type": 'account_type',
        "Account ID": 'id',
        "Balance": 'balance',
        "Branch": 'branch',
        "Created Date": 'date',
        "Account Officer": 'officer',
      },
      data: [{}],
      thead_texts: [
        "Product Name",
        "Customer Name",
        "Account Type",
        "Account ID",
        "Balance",
        "Branch",
        "Created Date",
        "Account Officer",
        "Action",
      ],
      empty: [],
      file: "",
      excel_file: "",
      submitting: false,
      alert: {
        status: "",
        title: "",
        description: "",
        show: false,
      },
      pages: 1,
      size: 5,
      viewData: {},
      filterText: "",
      filterEdit: [],
      showEditedValue: 0,
      showFilterModal: false,
      fetchAccount: {
        filters: [],
        pageIndex: 1,
        pageSize: 20,
        filterCategory: "",
        isFromFilter: false,
      },
      allItemToBatchAssign: [],
      selectAll: false,
      dialog:false,
      isSaving: false,
      assignedOfficerKey:"",
      assignedOfficerKey2: "",
      accountOfficers: [],
      modalText: {}
    };
  },

  watch: {
    paginations() {
      this.filterText = this.paginations.filterText ? this.paginations.filterText.replace(/_/g, " ") : "";
      this.filterEdit = this.paginations.filters ? this.paginations.filters : [];
      // if(this.bulkRepayments.filterText && this.bulkRepayments.filters) {
      //   this.getExportData();
      // }
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      bulkRepayments: (state) => {
        return state.bulkreassignment.allBulkReassign.data;
      },
      paginations: (state) => {
        return state.bulkreassignment.allBulkReassign;
      },
      loading: (state) => {
        return state.bulkreassignment.loading;
      },
    }),

    userCanViewAccountOfficer() {
      const account = this.user.scopes.find(item => item.scopeName === Role.TeamManagement);
      const { permissionsList } = account;
      return permissionsList.CanBulkReassignAccountOfficer;
    },

    download() {
      let data = [];
      if (this.bulkRepayments && this.bulkRepayments.length) {
        this.bulkRepayments.forEach((item) => {
          let new_obj = {};
          new_obj.product_name = item.productName;
          new_obj.customer_name = item.customerName;
          new_obj.account_type = item.accountType;
          new_obj.id = item.accountId;
          new_obj.balance = item.balance;
          new_obj.branch = item.branchName;
          new_obj.date = moment(item.creationDate).format("DD-MM-YYYY");
          new_obj.officer = item.accountOfficer;

          data.push(new_obj);
        });
      }
      return data;
    },
  },

  methods: {
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    reloadFilter() {
      this.deleteFilter()
      this.fetchAccount.isFromFilter = false;
      this.fetchAccount.filterCategory = "";
      this.fetchAccount.pageSize = 20;
      this.fetchAccount.filters = [];
      this.fetchAccount.pageIndex = 1;
      this.getBulkRepaymentsData();
    },
    deleteFilter() {
      this.filterText = "";
      this.filterEdit = [];
    },
    editFilter() {
      this.showEditedValue = Math.random();
      this.toggleFilterModal();
    },
    closeModal() {
      this.dialog = false;
      this.isSaving = false;
      this.selectAll = false;
      this.assignedOfficerKey = "";
      this.assignedOfficerKey2 = "";
      this.modalText = {};
      this.allItemToBatchAssign = [];
    },
    processReassign() {
      this.modalText.header = "Bulk Assign Accounts"
      this.modalText.subHead = `You have selected ${this.allItemToBatchAssign.length} accounts to be reassigned.`
      this.modalText.fromBulk = true;
      this.modalText.buttonText = "Bulk Assign";
      this.dialog = true
    },
    processSingleReassign(loanKey, customerKey, officerKey) {
      this.allItemToBatchAssign = [];
      this.allItemToBatchAssign.push(loanKey);
      const officerName = this.accountOfficers.find(user => user.userKey === officerKey);
      this.modalText.header = "Assign Account Officer";
      this.modalText.subHead = officerName ? officerName.name : "";
      this.modalText.fromBulk = true;
      this.modalText.buttonText = "Reassign";
      this.dialog = true
    },
    processUpdateAccountOfficer() {
      this.modalText.header = "Update Account Officer"
      this.modalText.subHead = ``
      this.modalText.fromUpdate = true;
      this.modalText.buttonText = "Update All Accounts";
      this.dialog = true
    },
    getAccountOfficer() {
      AccessApiService.get(
        `ProleanUser/GetBranchClientOfficer?branchKey=${""}`
      ).then((resp) => {
        this.accountOfficers = resp.data.data;
      })
    },
    sendFilter(value) {
      // const filterArray = value.map((obj) => {
      //   const payload = {};

      //   payload.filterField = obj.filterName;
      //   payload.operation = obj.filterOp;
      //   if (obj.value1 === '' || obj.value1 === null) {
      //     payload.fieldValues = [obj.value];
      //     return payload;
      //   }
      //   payload.fieldValues = [obj.value, obj.value1];
      //   return payload;
      // });
      const filterArray = value.map(obj => {
        const payload = {};

        payload.filterFieldName = obj.filterName;
        payload.filterFieldOperator = obj.filterOp;
        if (obj.value1 === '' || obj.value1 === null) {
          payload.filterFieldValues = [obj.value];
          return payload;
        }
        payload.filterFieldValues = [obj.value, obj.value1];
        return payload;
      });
      this.fetchAccount.filters = filterArray;
      this.fetchAccount.pageIndex = 1;
      this.fetchAccount.pageSize = 20;
      this.fetchAccount.filterCategory = "bulk_reassign_officer";
      this.fetchAccount.isFromFilter = true;
      this.getBulkRepaymentsData()
    },
    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Bulk Assignment List Download",
        Subject: "Bulk Assignment List Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("BulkAssignment");

      let sheetData = [[
        "Product Name",
        "Customer Name",
        "Account Type",
        "Account ID",
        "Balance",
        "Branch",
        "Created Date",
        "Account Officer",
      ]];

      if (this.bulkRepayments && this.bulkRepayments.length) {
        this.bulkRepayments.forEach((item) => {
          let new_arr = [];
          new_arr.push(item.productName);
          new_arr.push(item.customerName);
          new_arr.push(item.accountType);
          new_arr.push(item.accountId);
          new_arr.push(item.balance);
          new_arr.push(item.branchName);
          new_arr.push(moment(item.creationDate).format("DD-MM-YYYY"));
          new_arr.push(item.accountOfficer);

          sheetData.push(new_arr);
        });
      }

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["BulkAssignment"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },
    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },
    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "bulk_reassignment.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    pdfDownload() {
      const columns = [
        {title: "Product Name", dataKey: 'product_name'},
        {title: "Customer Name", dataKey: 'customer_name'},
        {title: "Account Type", dataKey: 'account_type'},
        {title: "Account ID", dataKey: 'id'},
        {title: "Balance", dataKey: 'balance'},
        {title: "Branch", dataKey: 'branch'},
        {title: "Created Date", dataKey: 'date'},
        {title: "Account Officer", dataKey: 'officer'},
      ];

      let doc = new jsPDF("l", "pt", "a4");
      doc.setFontSize(6);
      doc.autoTable(columns, this.download);
      doc.save("bulk_reassignment.pdf");
    },
    getBulkRepaymentsData() {
      this.$store.dispatch(GET_ALL_BULK_REASSIGNMENT, this.fetchAccount);
    },
    selectAllRows() {
      this.selectAll = !this.selectAll;
      this.allItemToBatchAssign = [];

      if (this.selectAll) {
        this.bulkRepayments.forEach((item) => {
          this.allItemToBatchAssign.push(item.accountKey);
        });
      }
    },
    selectRows() {
      if (this.allItemToBatchAssign.length == this.bulkRepayments.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },
    changePage(type) {
      if (type === "next") {
        this.fetchAccount.pageIndex++;
      } else if (type === "previous") {
        this.fetchAccount.pageIndex--;
      } else {
        this.fetchAccount.pageIndex = type;
      }

      this.getBulkRepaymentsData();
    },
    changeLength(e) {
      const { value } = e.target;
      this.fetchAccount.pageSize = parseInt(value);
      this.getBulkRepaymentsData();
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    colorType(status) {
      if (status == true) {
        return "color:#000000";
      } else {
        return "color:#DE791B";
      }
    },
    bulkAssign() {
      this.isSaving = true;
      let updateFlag =false;
      if(this.assignedOfficerKey2) {
        const officerKey = this.assignedOfficerKey
        // this.allItemToBatchAssign = this.bulkRepayments.filter(loan => loan.accountOfficerKey == officerKey).map(key => key.accountKey);
        this.allItemToBatchAssign = [officerKey];
        this.assignedOfficerKey = this.assignedOfficerKey2
        updateFlag = true;
      }
      const data = {
        accountAssignment: [
          {
            officerKey: this.assignedOfficerKey,
            accountKeys: this.allItemToBatchAssign,
          }
        ],
        updateFlag,
      }
      ApiService.post(
        `DepositAccount/upload-account-officer`,
        data
      ).then((resp) => {
        if(resp.data.statusCode == 409) {
          this.alertWarning(resp.data.message)
        } else {
          this.alertSuccess(resp.data.message)
        }
        this.getBulkRepaymentsData()
        this.closeModal();
      }).catch((err) => {
        this.isSaving = false;
        if(err.response.data.statusCode == 409) {
          this.alertWarning(err.response.data.message)
        } else {
          this.alertError(err.response.data.message)
        }
      })
    },
  },
  mounted() {
    this.getBulkRepaymentsData();
    this.getAccountOfficer();
  },
  filters: {
    formatDatePipe: function (date) {
      return date ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
};
</script>

<style scope>
.head-text {
  font-weight: 600;
  font-size: 1.8rem;
  font-family: "Montserrat-semibold";
  line-height: 32px;
  color: #1e1f20;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.box {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-direction: column;
}
.download-box {
  width: 185px;
  height: 40px;
  background: #e9e7e7;
  border-radius: 5px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #000000;
  text-align: center;
}
.btn-black {
  width: 165px;
  height: 40px;
  background: #000000;
  border-radius: 5px;
  border: 1px solid #000000;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 37px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
.dash-box {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hid-box {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.thead-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #9596a2;
  width: 13%;
  text-align: center;
}
.tbody-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #000000;
  width: 13%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ma-thead,
.ma-tbody {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.ma-table {
  display: flex;
  flex-direction: column;
}
.action {
  width: 95px;
  height: 35px;
  background: #e9e7e7;
  border-radius: 5px;
  border: 1px dashed #e9e7e7;
  font-weight: 500;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.action-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.action-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.disable-btn {
  background: #e9e7e7;
  width: 185px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e9e7e7;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 37px;
  color: #ffffff;
  text-align: center;
}
input[type="file"] {
  display: none;
}
.checkmark2 {
  top: -16px !important;
}
</style>
